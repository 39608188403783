<form (submit)="saveFilterOptions()" class="deal-search-filters-form" #searchForm="ngForm">
    <div data-layout="row" data-layout-gap="1rem">
        <div data-flex>
            <mat-form-field>
                <mat-label>Keywords</mat-label>
                <input (focus)="onTouched()" matInput mgdClearInput [(ngModel)]="workingFilterOptions.keyword"
                    name="keywords" placeholder="Keywords" class="mousetrap"
                    [disabled]="searchForm.invalid || filterByMarketAreaSubFormOpen" />
                <button mat-icon-button matSuffix type="reset" tabindex="-1" matTooltip="Clear (Ctrl + Backspace)"
                    (click)="clearKeyword()" *ngIf="workingFilterOptions.keyword">
                    <mat-icon>
                        clear
                    </mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="flex-250">
            <mat-slide-toggle (click)="onTouched()" [disabled]="filterByMarketAreaSubFormOpen" [(ngModel)]="searchHistoricalDeals"
                (ngModelChange)="onSearchHistoricalDealsChange()" class="search-historical-toggle"
                name="searchHistoricalDeals" labelPosition="before">
                Search Historical Deals
            </mat-slide-toggle>
        </div>
    </div>

    <div *ngIf="searchHistoricalDeals" class="promotion-period">
        <div class="period-icon">
            <mat-icon>
                access_time
            </mat-icon>
        </div>

        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(ngModel)]="selectedPromotionPeriod" name="selectedPromotionPeriod"
            [disabled]="filterByMarketAreaSubFormOpen">
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.LastWeek" (click)="onTouched()">
                Last Week
            </mat-button-toggle>
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.LastTwoWeeks" (click)="onTouched()">
                Last Two Weeks
            </mat-button-toggle>
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.LastMonth" (click)="onTouched()">
                Last Month
            </mat-button-toggle>
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.LastTwoMonths" (click)="onTouched()">
                Last Two Months
            </mat-button-toggle>
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.LastSixMonths" (click)="onTouched()">
                Last Six Months
            </mat-button-toggle>
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.LastYear" (click)="onTouched()">
                Last Year
            </mat-button-toggle>
            <mat-button-toggle [value]="dealSearchFiltersPromotionPeriod.Custom" (click)="onTouched()">
                Custom Period
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div data-layout="row" data-layout-align="center center" *ngIf="searchHistoricalDeals && showCustomDateRange"
        class="custom-promotion-period">
        <div class="flex-300">
            <mat-form-field>
                <mat-label>Promotion Period</mat-label>
                <mat-date-range-input [rangePicker]="rangePicker" mgdDateRangePicker [mgdTimezone]="appConfig?.timezone"
                    [min]="minDate" [max]="maxDate">
                    <input matStartDate placeholder="Start date" [(ngModel)]="searchStartDate" name="start"
                        #start="ngModel" readonly>
                    <input matEndDate placeholder="End date" [(ngModel)]="searchEndDate" name="end" #end="ngModel"
                        readonly required>
                </mat-date-range-input>
                <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker disabled="false"></mat-date-range-picker>

                <mat-error *ngIf="!start.control.getError('required') && start.errors">Invalid start date</mat-error>
                <mat-error *ngIf="!end.control.getError('required') && end.errors">Invalid end date</mat-error>
                <mat-error *ngIf="end.control.getError('required')">End date required</mat-error>
                <mat-error *ngIf="start.control.getError('required')">Start date required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="expandable-filters">
        <mat-accordion>
            <mat-expansion-panel [disabled]="filterByMarketAreaSubFormOpen">
                <mat-expansion-panel-header>
                    <mat-panel-title>Product Information Filters</mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div data-layout="row" data-layout-gap="1rem">
                        <div data-flex="33">
                            <mat-form-field>
                                <mat-label>Brand</mat-label>
                                <brand-selector mgdClearInput (click)="onTouched()" [(ngModel)]="workingBrand"
                                    (optionSelected)="addChip(dealSearchFiltersChipType.Brand, $event)"
                                    [disabled]="disabled" #brand="ngModel" name="brand">
                                </brand-selector>

                                <mat-hint>
                                    Select an option to filter by that brand
                                </mat-hint>

                                <button mat-icon-button matSuffix type="reset" tabindex="-1"
                                    matTooltip="Clear (Ctrl + Backspace)" (click)="clearWorkingBrand()"
                                    *ngIf="workingBrand">
                                    <mat-icon>
                                        clear
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div data-flex="33">
                            <mat-form-field>
                                <mat-label>Manufacturer</mat-label>
                                <manufacturer-selector mgdClearInput [(ngModel)]="workingManufacturer"
                                    (optionSelected)="addChip(dealSearchFiltersChipType.Manufacturer, $event)"
                                    [isDisabled]="disabled" #manufacturer="ngModel" name="manufacturer"
                                    (click)="onTouched()">
                                </manufacturer-selector>

                                <mat-hint>
                                    Select an option to filter by that manufacturer
                                </mat-hint>

                                <button mat-icon-button matSuffix type="reset" tabindex="-1"
                                    matTooltip="Clear (Ctrl + Backspace)" (click)="clearWorkingManufacturer()"
                                    *ngIf="workingManufacturer">
                                    <mat-icon>
                                        clear
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div data-flex="33">
                            <mat-form-field>
                                <mat-label>UPC</mat-label>
                                <input matInput mgdClearInput [(ngModel)]="workingUpc" #upc="ngModel"
                                    (keydown.enter)="addUpcChip(!upc.invalid, workingUpc, $event)"
                                    (blur)="setUpcFocused(false)" (focus)="setUpcFocused(true)" [upcValidator]="true"
                                    [disabled]="disabled" name="upc" placeholder="Upc" class="mousetrap"
                                    (click)="onTouched()" />

                                <mat-hint *ngIf="upcFocused && workingUpc">
                                    Press Enter to filter with this UPC
                                </mat-hint>

                                <mat-error *ngIf="upc.errors?.upcError">
                                    This UPC doesn't match any products in our system
                                </mat-error>

                                <button mat-icon-button matSuffix type="reset" tabindex="-1"
                                    matTooltip="Clear (Ctrl + Backspace)" (click)="clearWorkingUpc()"
                                    *ngIf="workingUpc">
                                    <mat-icon>
                                        clear
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel #locationPanel [disabled]="disabled">
                <mat-expansion-panel-header>
                    <mat-panel-title>Location Filters</mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div data-layout="row" class="market-area-filter-toggle">
                        <div data-flex="100">
                            <mat-slide-toggle (click)="onTouched()" [(ngModel)]="filterByMarketArea"
                                [disabled]="filterByMarketAreaSubFormOpen" name="marketArea" labelPosition="before">
                                Filter by Market Area
                            </mat-slide-toggle>
                        </div>
                    </div>

                    <div data-layout="row" data-layout-gap="1rem" *ngIf="!filterByMarketArea">
                        <div data-flex>
                            <mat-form-field>
                                <mat-label>Location</mat-label>
                                <location-picker [(ngModel)]="location" name="location" (focus)="onTouched()">
                                </location-picker>
                                <mat-error *ngIf="!location">
                                    This location is invalid
                                </mat-error>
                                <button mat-icon-button matSuffix type="reset" tabindex="-1"
                                    matTooltip="Clear (Ctrl + Backspace)" (click)="clearLocation()" *ngIf="location">
                                    <mat-icon>
                                        clear
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="flex-150">
                            <mat-form-field>
                                <mat-label>Search Radius (mi)</mat-label>
                                <input matInput mgdClearInput [(ngModel)]="distance" [disabled]="disabled"
                                    (focus)="onTouched()" min="0" step="1" name="radius"
                                    placeholder="Search Radius (mi)" type="number" />

                                <button mat-icon-button matSuffix type="reset" tabindex="-1"
                                    matTooltip="Clear (Ctrl + Backspace)" (click)="clearDistance()" *ngIf="distance">
                                    <mat-icon>
                                        clear
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>

                    <div data-layout="row" *ngIf="allChains?.length && !filterByMarketArea">
                        <div data-flex="100">
                            <mat-form-field>
                                <mat-label>Chains</mat-label>
                                <multi-selector mgdClearInput [(ngModel)]="chainIds" (ngModelChange)="onTouched()"
                                    [options]="allChains" displayField="name" #chains="ngModel" name="chains"
                                    valueField="id" placeholder="Chains">
                                </multi-selector>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="filterByMarketArea">
                        <div data-layout-gap="1rem" class="complex-filter-container">
                            <div data-flex="100" *ngIf="filterByMarketAreaSubFormOpen">
                                <div class="complex-filter-form">
                                    <div data-flex="20" data-layout-align="center center"  data-layout-gap="1rem" class="complex-filter-form-row">
                                        <mat-form-field>
                                            <mat-label>Chain</mat-label>
                                            <chain-selector [(ngModel)]="workingChain"
                                                (ngModelChange)="getMarketAreasForChain(workingChain?.id)" name="chain"
                                                placeholder="Chain">
                                            </chain-selector>
                                            <mat-hint>Select the Chain</mat-hint>
                                        </mat-form-field>
                                    </div>

                                    <div data-flex="80" data-layout-align="center center" data-layout-align="center center" class="complex-filter-form-row working-market-area-select">
                                        <mat-form-field>
                                            <mat-label>Market Areas</mat-label>
                                            <multi-selector mgdClearInput [(ngModel)]="workingMarketAreaIds"
                                                [disabled]="!workingChainMarketAreas?.length"
                                                [options]="workingChainMarketAreas" displayField="name"
                                                name="marketAreas" valueField="id" placeholder="Market Areas">
                                            </multi-selector>
                                            <mat-hint>({{ workingMarketAreaIds.length | i18nPlural :
                                                marketAreaText }})</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="subFormMarketAreaButtons" data-layout="row" data-layout-gap="1rem" data-layout-align="space-between end">
                                    <button mat-raised-button type="button" (click)="clearSelectedChain()">
                                        Cancel
                                    </button>
                                    <button
                                    mat-raised-button
                                    debounceClick
                                    [disabled]="!canSaveFilter"
                                    (click)="saveChainFilter()"
                                    type="button"
                                    class="main-action-button">
                                        {{ addingNewChainFilter ? "Add Filter" : "Save Filter" }}
                                    </button>
                                </div>
                            </div>

                            <div #chainListSubForm data-flex *ngIf="!filterByMarketAreaSubFormOpen">
                                <div *ngFor="let chain of chainMap | keyvalue" class="complex-filter-item"
                                    [class.selected]="isChainSelected(chain.key)" data-layout="row">
                                    <div data-flex class="complex-filter-item-label">
                                        {{ chain.value[0].chain.name }} ({{ chain.value.length | i18nPlural :
                                        marketAreaText }})
                                    </div>

                                    <div class="complex-filter-item-actions flex-120">
                                        <mat-icon [matTooltip]="getMarketAreaTooltip(chain.value)"
                                            matTooltipPosition="above" matTooltipClass="multiline">
                                            info
                                        </mat-icon>

                                        <button mat-icon-button type="button"
                                            (click)="editChainFilter(chain.key)">
                                            <mat-icon>
                                                edit
                                            </mat-icon>
                                        </button>

                                        <button mat-icon-button type="button"
                                            (click)="removeChainFilter(chain.key)">
                                            <mat-icon>
                                                delete
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div data-layout="row" (click)="setAddingNewChainFilter(true)"
                                    [class.selected]="addingNewChainFilter"
                                    class="complex-filter-item add-complex-filter">
                                    <div data-flex *ngIf="!addingNewChainFilter">
                                        <div data-flex class="complex-filter-item-label">
                                            Add a new chain filter
                                        </div>

                                        <div class="complex-filter-item-actions flex-40">
                                            <button mat-icon-button type="button">
                                                <mat-icon>
                                                    add
                                                </mat-icon>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <mat-chip-listbox *ngIf="chips?.length || showLocationChip" class="chips-wrapper">
        <mat-chip *ngIf="showLocationChip" (removed)="editLocation()">
            Location
            <button matChipRemove>
                <mat-icon>edit</mat-icon>
            </button>
        </mat-chip>
        <mat-chip *ngFor="let chip of chips" (removed)="onChipClick(chip)">
            {{ getChipLabel(chip) }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-listbox>

    <div class="actions" data-layout="row" data-layout-gap="1rem">
        <button mat-raised-button type="button" (click)="clear()"
            [disabled]="searchForm.invalid || filterByMarketAreaSubFormOpen">
            Clear
        </button>
        <button mat-raised-button debounceClick type="submit"
            [disabled]="searchForm.invalid || filterByMarketAreaSubFormOpen">
            <span>Search</span>
        </button>
    </div>
</form>
